.App {
  text-align: center;
  background-color: #0c1c1c;
  min-height: 100vh;
  height: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.abarLogo {
  height: auto;
  margin-left: 2vw;
  margin-top: 20px;
  width: 10vw;
  min-width: 150px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#container {
  display: flex;
  justify-content: center;
}

ul {
  display: flex;

  justify-content: space-between;
  margin: 0;
  margin-left: calc((100vw - 1020px) / 2);
  margin-right: calc((100vw - 1020px) / 2);

  padding: 0;
  width: 80%;
}

.selectImageSection {
  display: table-cell;
  vertical-align: middle;
  width: 30%;
}

li {
  list-style-type: none;
}

p {
  display: block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 1;
}

.redTitle {
  box-sizing: border-box;
  color: #fa8072;
  font-size: 36px;

  font-style: normal;
  font-weight: 700;
  margin-left: 10px;
  text-align: left;
  margin-right: 10px;
  font-family: "Montserrat", sans-serif;
  padding-top: 50px;
}

.redTitleContent {
  color: #f8e4ba;
  font-weight: 400;
  font-size: 15px;
  box-sizing: border-box;
  margin-left: 10px;
  margin-right: 10px;
  letter-spacing: normal;
  line-height: calc(16px + 1.6vmin);

  text-align: justify;
  list-style-type: disc;
  text-size-adjust: 100%;
  font-family: "Montserrat", sans-serif;
  padding-top: 50px;
  text-rendering: optimizelegibility;
}

.remain {
  margin-top: 25px;

  margin-bottom: 20px;
  color: #f8e4ba;
  font-weight: 400;
  font-size: 15px;
  box-sizing: border-box;

  width: 100%;

  letter-spacing: normal;
  height: 25px;
  text-align: center;
  list-style-type: disc;
  text-size-adjust: 100%;
  font-family: "Montserrat", sans-serif;

  text-rendering: optimizelegibility;
}

.teamTree {
  box-shadow: 0 1px;
  padding-bottom: 1px;
}

.connectButton {
  background-color: #f8e4ba;
  width: 80%;
  height: 10%;
  background-color: #f8e4ba;
  border-radius: 30px;
  font-size: 21px;
  margin-top: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-shadow: 0px 0px 0px rgb(129 0 25 / 79%);
  fill: #a51080;
  color: #a51080;
}

.burnButton {
  margin-top: 5px;
}

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  cursor: pointer;
}

button:active {
  outline: none;
  border: none;
}

button:hover {
  color: #ff1ff7;
}

button:focus {
  outline: 0;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
  min-height: 400px;
  width: 100%;
}

.buildSection {
  min-height: 300px;
  padding-bottom: 60px;
}

.burn {
  height: auto;
  min-height: 500px;
}

.imageSection {
  display: table-cell;
  vertical-align: middle;
  width: 49%;
}

.imageContainer {
  height: 500px;
  margin-right: 10px;
  margin-left: 10px;
  display: table;
}

.container {
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
}

.burnContainer {
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
}

.currentTree {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: calc(8px);
  margin-top: 10px;
  margin-bottom: 10px;
  text-shadow: 0px 0px 0px rgb(129 0 25 / 79%);
  fill: #f8e4ba;
  color: #f8e4ba;
}

.limitImage {
  margin-top: 10%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50%;
}

.burnlimitImage {
  width: 100%;
  margin-top: 80px;
  padding-bottom: 20px;
  display: flex;
  align-items: "space-between";
  height: auto;
}
.removeBottom {
  padding-bottom: 0px;
}

.previewImage {
  max-height: 100%;
  max-width: 100%;
  background-color: white;
}

.bloomTitle {
  margin-top: 50px;
  color: #30e74a;
  box-sizing: border-box;
  font-size: 29px;

  font-style: normal;
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.burnTitle {
  margin-bottom: 10px;
}

.buildTitle {
  margin-bottom: 30px;
}

.fireworkTitle {
  margin-bottom: 36px !important;
}

.bloomContent {
  color: #f8e4ba;
  font-weight: 700;
  font-size: 13px;
  box-sizing: border-box;
  letter-spacing: 2px;
  line-height: 27px;
  text-align: center;
  list-style-type: disc;
  text-size-adjust: 100%;
  font-family: "Montserrat", sans-serif;
  text-rendering: optimizelegibility;
  margin-bottom: -5px;
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

.divider {
  margin-top: 50px;
  margin-bottom: 50px;
  box-sizing: border-box;
  background-color: rgba(222, 208, 206, 0.24);
  height: 2px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Montserrat", sans-serif;
}
@media (min-width: 1020px) {
  /* your conditional / responsive CSS inside this condition */

  .redTitle {
    margin-left: calc((100vw - 1020px) / 2);
    margin-right: calc((100vw - 1020px) / 2);
    width: 1020px;
  }
  .redTitleContent {
    margin-left: calc((100vw - 1020px) / 2);
    margin-right: calc((100vw - 1020px) / 2);
    width: 1020px;
  }
  .container {
    margin-left: calc((100vw - 1020px) / 2);
    margin-right: calc((100vw - 1020px) / 2);
    width: 1020px;
  }

  .burnContainer {
    margin-left: calc((100vw - 1020px) / 2);
    margin-right: calc((100vw - 1020px) / 2);
    width: 1020px;
  }
  .imageContainer {
    margin-left: calc((100vw - 1020px) / 2);
    margin-right: calc((100vw - 1020px) / 2);
    width: 1020px;
  }
  .divider {
    margin-left: calc((100vw - 1020px) / 2);
    margin-right: calc((100vw - 1020px) / 2);
    width: 1020px;
  }
  .section {
    width: 50%;
  }
}

.MuiInputBase-input {
  color: "white";
}

.select {
  background-color: transparent;
  border-width: 0px;
  border-bottom-width: 1px;
  color: #f8e4ba;
  width: 100%;
  font-size: 20px;
  margin-top: 20px;
}

.blankTree {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: "black";
}

.burnTreeImage {
  padding-bottom: 0;
}

.inner {
  position: relative;
  height: auto;
}
.full-height {
  height: 100%;
}

.testButtonContainer {
  position: fixed;
  right: 100px;
  top: 150px;
}

.phaseContainer {
  margin-top: 110px;
  margin-bottom: 20px;
  display: flex;
  width: 82%;
  height: 80%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.phaseSection {
  width: 48%;
  height: 48%;
  border-radius: 20px;
  margin: 1%;
  margin-bottom: 10px;

  margin-top: 0;
  background-color: #f8e4ba;
  display: flex;
  align-items: center;
  justify-content: center;
}

#bloomOl {
  counter-reset: item;
}

#bloomOl {
  list-style-type: none;
  position: relative;
}

#bloomOl li:before {
  content: counter(item) ". ";
  counter-increment: item;
  color: #f8e4ba;
  position: absolute;
  left: 0;
  text-align: right;
  width: 32px;
}
